import React, { FC } from "react";
import { OfferTemplate } from "@templates/OfferTemplate";
import { graphql } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { useTranslation } from "gatsby-plugin-react-i18next";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";
import { Routes } from "@routes";

const D3FloorPlanVisualizations: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  return (
    <OfferTemplate
      title={ct("title-offer-3d-floor-plan")}
      description={ct("description-offer-3d-floor-plan")}
      heroTitle={t("3d-floor-plan.hero.title")}
      heroDescription={t("3d-floor-plan.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.heroImage.childImageSharp.gatsbyImageData}
    >
      <UniversalSection
        title={t("3d-floor-plan.sections.0.title")}
        description={t("3d-floor-plan.sections.0.description", {
          returnObjects: true,
        })}
        fromRight
        images={data.firstSectionGallery.floorPlans3D.map(
          (image) => image.localFile.childImageSharp.gatsbyImageData
        )}
        dotsReverseColor
      />
      <UniversalSection
        title={t("3d-floor-plan.sections.1.title")}
        description={t("3d-floor-plan.sections.1.description", {
          returnObjects: true,
        })}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
        btnText={t("see-realization")}
        btnTo={`${Routes.RealisedProjects}osiedle-pod-miastem-kunow-1/`}
      />

      <UniversalSection
        title={t("3d-floor-plan.sections.2.title")}
        description={t("3d-floor-plan.sections.2.description", {
          returnObjects: true,
        })}
        images={[data.thirdSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
      />

      <UniversalSection
        title={t("3d-floor-plan.sections.3.title")}
        description={t("3d-floor-plan.sections.3.description", {
          returnObjects: true,
        })}
        images={[data.fourthSectionImage.childImageSharp.gatsbyImageData]}
      />

      <UniversalSection
        title={t("3d-floor-plan.sections.4.title")}
        description={t("3d-floor-plan.sections.4.description", {
          returnObjects: true,
        })}
        images={[data.fifthSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
        dotsReverseColor
      />

      <UniversalSection
        title={t("3d-floor-plan.sections.5.title")}
        description={t("3d-floor-plan.sections.5.description", {
          returnObjects: true,
        })}
        images={data.sixthSectionGallery.interior.map(
          (image) => image.localFile.childImageSharp.gatsbyImageData
        )}
        btnText={t("see-more")}
        btnTo={Routes.InteriorVisualizations}
        btnType="unframed"
      />

      <WhatWeCanDoMore exclude={5} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    heroImage: ChildImageSharp;
    firstSectionGallery: {
      floorPlans3D: { localFile: ChildImageSharp }[];
    };
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
    fourthSectionImage: ChildImageSharp;
    fifthSectionImage: ChildImageSharp;
    sixthSectionGallery: {
      interior: { localFile: ChildImageSharp }[];
    };
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    heroImage: file(name: { eq: "3d-floor-plan-visualizations_hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }

    firstSectionGallery: strapiVisualization {
      floorPlans3D {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
      }
    }

    secondSectionImage: file(name: { eq: "3d-floor-plan-visualizations_0" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    thirdSectionImage: file(name: { eq: "3d-floor-plan-visualizations_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    fourthSectionImage: file(name: { eq: "3d-floor-plan-visualizations_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    fifthSectionImage: file(name: { eq: "3d-floor-plan-visualizations_3" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    sixthSectionGallery: strapiVisualization {
      interior {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default D3FloorPlanVisualizations;
